import React from 'react';

function AboutMe(){
    return (
        <div id="AboutMe" class="aboutMe container-sm">
            <h4>About Me</h4>
            <p>Hello! My name is Devin Wiggins!</p>
            <p>
                I'm currently 21 years old and a Computer Programming college graduate.<br/>
                In my free time I enjoy playing video games (who doesn't now-a-days?), spending time with friends and family, exercising, and occasionally some small programming projects.<br/>






            </p>

        </div>
    );
}
export default AboutMe;